<template>
<div>
    <Header />

     <div class="banner bg-dark text-white">
            <div class="container">
                <div class="row">
                    <div class="col py-5">
                        <h1><b>Nuestra historia</b></h1>
                    </div>
                </div>
            </div>
        </div>

        <div class="py-5 bg-gradient pb-10 arrow-bottom">
            <div class="container">
                <div class="row pb-5">
                    <div class="col-6 col-md-3 offset-md-2 text-center">
                        <img src="@/assets/img/rebeca-lan.jpg" alt="Rebeca Lan" class="rounded-circle img-fluid mb-3" height="150">
                        <br>
                        <p class="text-center"><b>Rebeca Lan</b><br>
                        (1946 – 2005) </p>
                    </div>
                    <div class="col-6 col-md-3 offset-md-2 text-center">
                        <img src="@/assets/img/rashel-cohen-lan.jpg" alt="Rashel Stephanie Cohen Lan" class="rounded-circle img-fluid mb-3" height="150">
                        <br>
                        <p class="text-center"><b>Rashel Stephanie Cohen Lan</b><br>
                        Fundadora y Presidenta</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <p>Rashel Cohen Lan tenía apenas 13 años cuando fue separada de su madre, la señora Rebeca Lan, quien estuvo injustamente durante ocho años recluida, presenció el maltrato y la soledad que padecen las mujeres privadas de la libertad; además de la angustia ante el desamparo en que quedaban sus hijos.</p>
                        <p>Rebeca Lan era una mujer amorosa y valiente, tras superar su propia condición de dolor, se convirtió en un faro de luz para sus compañeras. Logró convertir el tiempo de encierro, en un peldaño para crecer y reivindicarse, para creer en sí mismas y sobre todo, para recuperar la esperanza de una vida digna en libertad.</p>
                        <p>En el 2016 Rashel decide crear la Fundación Rebeca Lan y dar continuidad a la noble labor de seguir ayudando a las mujeres privadas de libertad y a sus hijos. Con amor, valentía y fortalezas que aprendió de su madre, Rashel les acerca la posibilidad de reinsertarse a la sociedad por medio de la dignificación de espacios, sanación emocional, el fortalecimiento del vínculo familiar y la inclusión laboral.</p>
                        <p>A través de los años, con determinación, carisma y perseverancia, ha  logrado llevarles  luz a muchas mujeres y sus familias. Así, ha sumado a centenas de voluntarios convencidos del trabajo de la fundación, en la misión de ayudar a devolver esperanza a estas mujeres que lo han perdido todo. También ha creado alianzas con diferentes organismos, iniciativa privada y dependencias gubernamentales que han apoyado a esta causa.</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="pt-10 pb-5 bg-gradient">
            <div class="container">
                <div class="py-5">
                    <div id="mision" class="card-deck mb-5">

                        <div class="card shadow border-0">
                            <div class="card-body">
                                <h2 class="font-weight-bold text-secret h5">Misión</h2>
                                <p>Contribuir en la reinserción social de mujeres privadas de la libertad y beneficiar a sus hijos y familiares a través del <b>Modelo Rebeca Lan</b> orientado a desarrollar competencias y habilidades de las mujeres en lo personal, familiar y laboral, que faciliten su reintegración a la sociedad y disminuyan sus probabilidades de reincidencia.</p>
                            </div>
                        </div>

                        <div class="card shadow border-0">
                            <div class="card-body">
                                <h2 class="font-weight-bold text-secret h5">Visión</h2>
                                <p>Replicar el Modelo de Reinserción Social Rebeca Lan en más Centros Penitenciarios del país e impactar a un mayor número de mujeres privadas y ex privadas de la libertad y sus hijos, siendo una organización autosuficiente y autosustentable.</p>
                            </div>
                        </div>

                    </div>
                    <div id="valores" class="row mb-3">
                        <div class="col">
                            <h2 class="font-weight-bold text-secret h5">Valores</h2>
                        </div>
                    </div>
                    <div class="card-deck">

                        <div class="card shadow border-0 mb-4">
                            <div class="card-body">
                                <p><b>Confianza</b></p>
                                <p>Fomentamos un vínculo de confianza entre la comunidad de <b>mujeres en la cárcel</b>, sus grupos familiares y autoridades.</p>
                            </div>
                        </div>

                        <div class="card shadow border-0 mb-4">
                            <div class="card-body">
                                <p><b>Perseverancia</b></p>
                                <p>Por una <b>labor incansable</b> que sume cada vez más beneficiados a través de nuestros programas de impacto.</p>
                            </div>
                        </div>

                        <div class="card shadow border-0 mb-4">
                            <div class="card-body">
                                <p><b>Respeto</b></p>
                                <p>Respetamos a las <b>mujeres</b>, a sus familias, y a las autoridades.</p>
                            </div>
                        </div>

                    </div>
                    <div class="card-deck">

                        <div class="card shadow border-0 mb-4">
                            <div class="card-body">
                                <p><b>Honestidad</b></p>
                                <p>Cumplimos con coherencia nuestros <b>compromisos</b>, al tener transparencia en todas nuestras acciones.</p>
                            </div>
                        </div>

                        <div class="card shadow border-0 mb-4">
                            <div class="card-body">
                                <p><b>Amor</b></p>
                                <p>El amor es el motor que impulsa todas y cada una de nuestras acciones.</p>
                            </div>
                        </div>

                        <div class="card shadow border-0 mb-4">
                            <div class="card-body">
                                <p><b>Humildad</b></p>
                                <p>Porque con la humildad de reconocer que necesitamos de otros, nos esforzamos día a día, <b>sumando apoyos</b> que nos acerquen a nuestra misión de vida.</p>
                            </div>
                        </div>

                    </div>
                </div>

                <div id="premios" class="premios pt-5">
                    <div class="row">
                        <div class="col text-center">
                            <h2 class="pb-3 mb-4"><b>Premios</b></h2>
                        </div>
                    </div>
                    <div class="row row-cols-1 row-cols-md-4">
                        <div class="col">
                            <img src="@/assets/img/premios/premios-marcos-katz-halpern.jpg" alt="Premio Agentes de Cambio Marcos Katz Halpern 2018" class="rounded img-fluid mb-3">
                            <p><b>Premio Agentes de Cambio Marcos Katz Halpern 2018</b></p>
                            <p>Tercer lugar del Premio de Emprendimiento Social por ser un Organismo de la Sociedad Civil encaminada a mejorar la calidad de vida de las comunidades vulnerables del país, que está resolviendo problemas sociales a través de enfoques innovadores.</p>
                        </div>
                        <div class="col">
                            <img src="@/assets/img/premios/premios-derechos-humanos.jpg" alt="Premio Regional de Derechos Humanos 2019" class="rounded img-fluid mb-3">
                            <p><b>Premio Regional de Derechos Humanos 2019</b></p>
                            <p>Galardón por parte de la Federación Mexicana de Organismos Públicos de la Comisión Nacional de Derechos Humanos por su destacado trabajo en la promoción efectiva y en la defensa de los derechos humanos.</p>
                        </div>
                        <div class="col">
                            <img src="@/assets/img/premios/premios-accion-voluntaria.jpg" alt="Premio Nacional de Acción Voluntaria y Solidaria 2019" class="rounded img-fluid mb-3">
                            <p><b>Premio Nacional de Acción Voluntaria y Solidaria 2019</b></p>
                            <p>El Gobierno de México otorgo el Premio Nacional de Acción Voluntaria y Solidaria 2019 a Rashel Cohen Lan, en la categoría individual por su labor humanitaria  voluntaria en beneficio de la comunidad, específicamente por el trabajo que ha venido haciendo en beneficio de las mujeres privadas de la libertad, sus familias e hijos desde hace ya varios años.</p>
                        </div>
                        <div class="col">
                            <img src="@/assets/img/premios/premios-mujer-de-valor.jpg" alt="Mujer de Valor 2019 por L’Oreal Paris" class="rounded img-fluid mb-3">
                            <p><b>Mujer de Valor 2019 por L’Oreal Paris</b></p>
                            <p>L’Oreal Paris reconoció a Rashel Cohen Lan,  como una Mujer de Valor por el trabajo que hace por las mujeres privadas de la libertad y sus hijos.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <Footer />
</div>
</template>

<style lang="less">
    @import "@/assets/css/colors.less";
    @import "@/assets/css/main.less";
    @import "./css/nosotros.less";
    .bg-dark{
        overflow: hidden;
        position: relative;
        background: rgb(0,0,0);
        background: linear-gradient(90deg, rgba(0,0,0,1) 50%, rgba(0,0,0,0) 100%);
        text-shadow: 0 1px 15px rgba(0,0,0,.8);

        &::before {
            background-image: url('../../assets/img/bg-image.jpg');
            content: "";
            background-size: cover;
            position: absolute;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
            opacity: 0.35;
        }
    }
</style>

<script>
import Header from '@/modules/Templates/Header'
import Footer from '@/modules/Templates/Footer'

export default {
    name: "Nosotros",
    components:{
        Header,
        Footer
    },
    mounted(){
       if(window.location.hash) {
            const id = window.location.hash.substring(1);
            const yourElement = document.getElementById(id);
            const y = yourElement.getBoundingClientRect().top + window.pageYOffset;

            window.scrollTo({top: y, behavior: 'smooth'});
        }
    }
}
</script>

